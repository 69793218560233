<template>
  <div>
    <div class="header">
      <div class="nestification">
        <h1>{{ this.$t("Contact.contact") }}</h1>
      </div>
    </div>
    <div class="banner">
      <h1>{{ this.$t("Contact.Tip.title") }}</h1>
      <ul>
        <li>
          {{ this.$t("Contact.Tip.setion_a") }}
        </li>
        <li>
          {{ this.$t("Contact.Tip.setion_b") }}
        </li>
        <li>
          {{ this.$t("Contact.Tip.setion_c") }}
        </li>
        <li>
          {{ this.$t("Contact.Tip.setion_d") }}
        </li>
        <li>
          {{ this.$t("Contact.Tip.setion_e") }}
        </li>
        <li>
          {{ this.$t("Contact.Tip.setion_f") }}
        </li>
      </ul>
    </div>
    <div class="form-contact">
      <el-form :model="model" :rules="rules" ref="ruleForm">
        <el-form-item
          :label="this.$t('consult.companyName')"
          prop="companyName"
        >
          <el-input
            v-model="model.companyName"
            :placeholder="this.$t('consult.companyName')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('consult.name')" prop="name">
          <el-input
            v-model="model.name"
            :placeholder="this.$t('consult.name')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('consult.number')" prop="phoneNumber">
          <el-input
            v-model="model.phoneNumber"
            :placeholder="this.$t('consult.number')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('consult.email')" prop="email">
          <el-input
            v-model="model.email"
            :placeholder="this.$t('consult.email')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('consult.content')" prop="select">
          <el-input
            :value="model.select"
            :placeholder="this.$t('consult.content')"
            @focus="chooseHandler"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('consult.message')">
          <el-input
            v-model="model.message"
            :placeholder="this.$t('consult.message')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span class="must">*</span>
          <el-checkbox v-model="model.agreement"></el-checkbox>
          <span class="agreement" @click="$router.push('/privacyPolicy')">{{
            this.$t("consult.privacy")
          }}</span>
          <span class="agree">{{ this.$t("consult.agree") }}</span>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button
            :disabled="!submitClickStatus"
            :class="submitStatus ? '' : 'color'"
            type="primary"
            @click="submitHandler"
            >{{ this.$t("consult.submit") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="modal" v-show="isEdit">
      <div class="dialog">
        <div class="dialog-header">
          <h1>{{ this.$t("consult.content") }}</h1>
          <i @click="isEdit = false" class="close el-icon-close"></i>
        </div>
        <ul>
          <li
            :class="{ act: activeId === 1 }"
            @click="Select(1, 'Contact.Contents.cleaningRobot')"
          >
            {{ this.$t("Contact.Contents.cleaningRobot") }}
          </li>
          <li
            :class="{ act: activeId === 2 }"
            @click="Select(2, 'Contact.Contents.MAXHUB')"
          >
            {{ this.$t("Contact.Contents.MAXHUB") }}
          </li>
          <li
            :class="{ act: activeId === 3 }"
            @click="Select(3, 'Contact.Contents.eMeet')"
          >
            {{ this.$t("Contact.Contents.eMeet") }}
          </li>
          <li
            :class="{ act: activeId === 4 }"
            @click="Select(4, 'Contact.Contents.robot')"
          >
            {{ this.$t("Contact.Contents.robot") }}
          </li>
          <li
            :class="{ act: activeId === 5 }"
            @click="Select(5, 'Contact.Contents.car')"
          >
            {{ this.$t("Contact.Contents.car") }}
          </li>
          <li
            :class="{ act: activeId === 6 }"
            @click="Select(6, 'Contact.Contents.others')"
          >
            {{ this.$t("Contact.Contents.others") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    //验证规则
    var validateCompanyName = (rule, value, callback) => {
      if (!value) {
        this.submitStatus.companyName = false;
        console.log(this);
        return callback(new Error(this.$t("consult.error.notNull_company")));
      } else {
        this.submitStatus.companyName = true;
        return callback();
      }
    };
    var validatePhoneNumer = (rule, value, callback) => {
      if (!value) {
        this.submitStatus.phoneNumber = false;
        return callback(new Error(this.$t("consult.error.notNull_number")));
      } else {
        // 如需规则，在此处定义；按需定义，现在直接放行
        this.submitStatus.phoneNumber = true;
        return callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (!value) {
        this.submitStatus.email = false;
        return callback(new Error(this.$t("consult.error.notNull_email")));
      } else {
        const reg =
          /^[-_A-Za-z0-9\.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(value)) {
          this.submitStatus.email = false;
          return callback(new Error(this.$t("consult.error.ture_email")));
        } else {
          this.submitStatus.email = true;
          return callback();
        }
      }
    };
    var validateSelect = (rule, value, callback) => {
      if (!value) {
        this.submitStatus.select = false;
        return callback(new Error(this.$t("consult.error.notNull_content")));
      } else {
        this.submitStatus.select = true;
        return callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (!value) {
        this.submitStatus.name = false;
        return callback(new Error(this.$t("consult.error.notNull_name")));
      } else {
        this.submitStatus.name = true;
        return callback();
      }
    };

    return {
      //表单绑定的数据
      model: {
        companyName: "",
        name: "",
        phoneNumber: "",
        email: "",
        select: "",
        agreement: false,
        message: "",
      },
      // ele校验规则
      rules: {
        companyName: [
          { required: true, validator: validateCompanyName, trigger: "blur" },
        ],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        phoneNumber: [
          { required: true, validator: validatePhoneNumer, trigger: "blur" },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        select: [
          { required: true, validator: validateSelect, trigger: "blur" },
        ],
      },
      // 记录表单必要数据-为按钮禁用做准备
      submitStatus: {
        companyName: false,
        name: false,
        phoneNumber: false,
        email: false,
        select: false,
      },
      //选择框当前激活的id
      activeId: 1,
      //当前是否为编辑模式
      isEdit: false,
      //发axios需要的数据
      body: {
        personalizations: [
          {
            to: [
              {
                email: "",
              },
            ],
          },
        ],
        from: {
          email: "info@enway.co.jp",
        },
        subject: "",
        content: [
          {
            type: "",
            value: "",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    //设置计算属性，依赖数据为submitStatus以及model.agreement，控制表单的禁用状态..
    submitClickStatus: {
      get() {
        if (
          this.submitStatus.companyName &&
          this.submitStatus.name &&
          this.submitStatus.phoneNumber &&
          this.submitStatus.email &&
          this.submitStatus.select &&
          this.model.agreement
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  watch: {
    isEdit: {
      handler(value) {
        if(value){
          document.documentElement.style.overflow = "hidden";
        }else{
          document.documentElement.style.overflow = "auto";
        }
      },
    },
  },
  methods: {
    //聚焦于内容框的触发，打开弹层，触发选择
    chooseHandler() {
      this.isEdit = true;
    },
    
    //选择内容
    Select(id, select) {
      console.log(select);
      if(this.activeId !== id) this.activeId = id;
      this.model.select = this.$t(select);
      this.$refs.ruleForm.validateField("select", () => {
        /**/
      });
      this.isEdit = false;
    },
    //发送邮件
    submitHandler() {
      var subject = "お問い合わせ";
      var to = "info@enway.co.jp";
      var cc = this.model.email;
      var body =
        "会社名:" +
        this.model.companyName +
        "\n" +
        "お名前:" +
        this.model.name +
        "\n" +
        "電話番号:" +
        this.model.phoneNumber +
        "\n" +
        "メールアドレス:" +
        cc +
        "\n" +
        "内容:" +
        this.model.select +
        "\n" +
        "メッセージ:" +
        this.model.message;
      var urls ="mailto:" + to + "?cc=" + cc + "&subject=" + subject + "&body=" + body;
      this.body.personalizations[0].to[0].email = to;
      this.body.subject = subject;
      this.body.content[0].type = "text/plain";
      this.body.content[0].value = body;
      let data = {
        mimeType: "text",
        subject: subject,
        content: body,
        to: [
          {
            userName: "",
            address: to,
          },
        ],
      };
      //置空，重置
      this.model = {
        companyName: "",
        name: "",
        phoneNumber: "",
        email: "",
        select: "",
        agreement: false,
        message: "",
      };
      this.submitStatus = {
        companyName: false,
        name: false,
        phoneNumber: false,
        email: false,
        select: false,
      };

      axios.defaults.baseURL = "https://a.imamemo.com/";
      axios({
        method: "post",
        url: "search/v2/emails",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      })
        .then(() => {
          this.$message({
            message: this.$t("consult.submitSuccess"),
            type: "success",
          });
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  height: 56vw;
  background: url("../../assets/mobileContact/header.png") no-repeat center;
  background-size: cover !important ;
  display: flex;
  justify-content: center;
  align-items: center;
  .nestification {
    width: 64vw;
    height: 13.8667vw;
    background: rgba(225, 225, 225, 0.39);
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 5.3333vw;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 7.4667vw;
      color: #48464b;
    }
  }
}
.banner {
  overflow: hidden;
  h1 {
    padding: 0 4.2667vw;
    margin: 8vw 0 5.3333vw 0;
    font-size: 4.2667vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 8.5333vw;
    color: #000000;
    text-align: center;
  }
  ul {
    padding: 0 4.2667vw;
    li {
      font-size: 3.2vw;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 5.3333vw;
      color: #333333;
      margin-bottom: 5.3333vw;
    }
  }
}
.form-contact {
  padding: 3.2vw 4.2667vw 10.6667vw;
  //   height: 199.4667vw;
  background: rgba(242, 245, 248, 0.39);

  /deep/.el-form {
    height: 100%;
    background: #fff;
    padding: 4.8vw 3.2vw 8.8vw 3.2vw;
    .el-form-item__content {
      .must {
        margin-right: 2.6667vw;
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.5333vw;
        color: #ee0000;
      }
      .agreement {
        margin-left: 2.6667vw;
        margin-right: 2.6667vw;
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.5333vw;
        color: #0064e6;
      }
      .agree {
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.5333vw;
        color: #666666;
      }
      .el-button {
        margin: 0 auto;
        width: 49.8667vw;
        height: 11.7333vw;
        border-radius: 13.3333vw;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          text-align: center;
          letter-spacing: 1vw;
          font-size: 4.2667vw;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 5.8667vw;
        }
      }
      .color {
        background: #0064e6;
      }
    }
  }
}
.modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.39);
  .dialog {
    position: absolute;
    top: 31.4667vw;
    left: 4.2667vw;
    background: #fff;
    width: 91.4667vw;
    height: 130.6667vw;
    padding: 0 5.3333vw;
    box-sizing: border-box;
    .dialog-header {
      height: 16.5333vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      position: relative;
      h1 {
        font-size: 4.2667vw;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 5.8667vw;
        color: #333333;
      }
      .close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #5f6368;
      }
    }
    ul {
      padding: 8vw 0 0 0;
      li {
        height: 10.6667vw;
        margin-bottom: 5.3333vw;
        display: flex;
        align-items: center;
        padding-left: 5.3333vw;
        border-radius: 1.0667vw;
        font-size: 3.7333vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 5.3333vw;
        color: #333333;
      }
      li:last-child {
        margin: 0;
      }
      .act {
        background: #0870d1;
        color: #fff;
      }
    }
  }
}
</style>