<template>
  <div class="contact-box">
    <div class="modal" @click.self="closeEdit" v-show="isEdit">
      <form action="" class="dialog">
        <div class="title">
          <span>内容</span>
          <i @click="closeEdit" class="close el-icon-close"></i>
        </div>
        <div class="dialog-content">
          <el-radio-group @input="closeEdit" v-model="model.select">
            <el-radio-button :label="this.$t('Contact.Contents.cleaningRobot')"></el-radio-button>
            <el-radio-button :label="this.$t('Contact.Contents.MAXHUB')"></el-radio-button>
            <el-radio-button :label="this.$t('Contact.Contents.eMeet')"></el-radio-button>
            <el-radio-button :label="this.$t('Contact.Contents.robot')"></el-radio-button>
            <el-radio-button :label="this.$t('Contact.Contents.car')"></el-radio-button>
            <el-radio-button :label="this.$t('Contact.Contents.others')"></el-radio-button>
          </el-radio-group>
        </div>
      </form>
    </div>
    <div class="banner">
      <!-- <img width="100%" src="@/assets/contact/banner/contact-banner@2x.png" /> -->
      <div class="banner-container">
        {{ this.$t("Contact.contact") }}
      </div>
    </div>
    <div class="info-box">
      <div class="info">
        <p>{{ this.$t("Contact.Tip.title") }}</p>
        <ul>
          <li>
            {{ this.$t("Contact.Tip.setion_a") }}
          </li>
          <li>
            {{ this.$t("Contact.Tip.setion_b") }}
          </li>
          <li>
            {{ this.$t("Contact.Tip.setion_c") }}
          </li>
          <li>
            {{ this.$t("Contact.Tip.setion_d") }}
          </li>
          <li>
            {{ this.$t("Contact.Tip.setion_e") }}
          </li>
          <li>
            {{ this.$t("Contact.Tip.setion_f") }}
          </li>
        </ul>
      </div>
    </div>
    <el-form :model="model" :rules="rules" ref="formRef" class="container" label-position="right" label-width="371px">
      <el-form-item :label="this.$t('consult.companyName')" prop="companyName">
        <el-input :placeholder="this.$t('consult.companyName')" v-model="model.companyName"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('consult.name')" prop="name">
        <el-input :placeholder="this.$t('consult.name')" v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('consult.number')" prop="phoneNumber">
        <el-input :placeholder="this.$t('consult.number')" v-model="model.phoneNumber"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('consult.email')" prop="email">
        <el-input :placeholder="this.$t('consult.email')" v-model="model.email"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('consult.content')" prop="select">
        <el-input :placeholder="this.$t('consult.content')" :value="model.select" readonly @focus="stopScroll"
          suffix-icon="el-icon-arrow-right"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" :label="this.$t('consult.message')">
        <el-input :placeholder="this.$t('consult.message')" v-model="model.message"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 80px" prop="agreement">
        <el-checkbox v-model="model.agreement"></el-checkbox>
        <span class="agree-checkbox" @click="$router.push('/privacyPolicy')">{{
          this.$t("consult.privacy")
        }}</span>
        <span class="agree-agree">{{ this.$t("consult.agree") }}</span>
      </el-form-item>
      <el-form-item label-width="0" style="text-align: center; margin: 0">
        <el-button :disabled="!isSubmit" :class="submitStatus ? 'submit' : 'submit color'" type="primary"
          @click="submitHandler">{{ this.$t("consult.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
    
<script>
import axios from "axios";
export default {
  data() {
    return {
      body: {
        personalizations: [
          {
            to: [
              {
                email: "",
              },
            ],
          },
        ],
        from: {
          email: "info@enway.co.jp",
        },
        subject: "",
        content: [
          {
            type: "",
            value: "",
          },
        ],
      },
      model: {
        companyName: "",
        name: "",
        phoneNumber: "",
        email: "",
        select: "",
        agreement: false,
        message: "",
      },
      isEdit: false,
      submitStatus: {
        // type: true,
        companyName: false,
        name: false,
        phoneNumber: false,
        email: false,
        select: false,
      },
      rules: {
        companyName: [
          {
            validator: this.validateCompanyName,
            trigger: "blur",
            required: true,
          },
        ],
        name: [
          { validator: this.validateName, required: true, trigger: "blur" },
        ],
        phoneNumber: [
          {
            required: true,
            trigger: "blur",
            validator: this.validatePhoneNumer,
          },
        ],
        email: [
          { required: true, trigger: "blur", validator: this.validateEmail },
        ],
        select: [
          { validator: this.validateSelect, required: true, trigger: "blur" },
        ],
        agreement: [
          {
            required: true,
            trigger: "blur",
            validator: this.validateAgreement,
          },
        ],
      },
    };
  },
  created() { },
  mounted() { },
  computed: {
    // 此属性判断提交按钮可以不可以点击？是否为disabled状态！
    isSubmit: {
      get() {
        if (
          this.submitStatus.companyName &&
          this.submitStatus.name &&
          this.submitStatus.phoneNumber &&
          this.submitStatus.email &&
          this.submitStatus.select &&
          this.model.agreement
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  methods: {
    //提交功能，发axios的..
    submitHandler() {
      var subject = "お問い合わせ";
      var to = "info@enway.co.jp";
      var cc = this.model.email;
      var body =
        "会社名:" +
        this.model.companyName +
        "\n" +
        "お名前:" +
        this.model.name +
        "\n" +
        "電話番号:" +
        this.model.phoneNumber +
        "\n" +
        "メールアドレス:" +
        cc +
        "\n" +
        "内容:" +
        this.model.select +
        "\n" +
        "メッセージ:" +
        this.model.message;
      var urls =
        "mailto:" + to + "?cc=" + cc + "&subject=" + subject + "&body=" + body;
      this.body.personalizations[0].to[0].email = to;
      this.body.subject = subject;
      this.body.content[0].type = "text/plain";
      this.body.content[0].value = body;
      let data = {
        mimeType: "text",
        subject: subject,
        content: body,
        to: [
          {
            userName: "",
            address: to,
          },
        ],
      };
      //置空，重置
      this.model = {
        companyName: "",
        name: "",
        phoneNumber: "",
        email: "",
        select: "",
        agreement: false,
        message: "",
      };
      this.submitStatus = {
        companyName: false,
        name: false,
        phoneNumber: false,
        email: false,
        select: false,
      };
      axios.defaults.baseURL = "https://a.imamemo.com/";
      axios({
        method: "post",
        url: "search/v2/emails",
        headers: {
          Authorization:
            "Bearer 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",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      })
        .then(() => {
          this.$message({
            message: this.$t("consult.submitSuccess"),
            type: "success",
          });
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    // 打开弹窗定住下面的页面不许动
    stopScroll() {
      document.documentElement.style.overflow = "hidden";
      this.isEdit = true;
    },
    //关闭弹窗清除副作用
    closeEdit() {
      document.documentElement.style.overflow = "auto";
      this.$refs.formRef.validateField("select", (eMes) => {
        /**/
      });
      this.isEdit = false;
    },
    //以下validate系列函数为表单验证的验证函数
    validateCompanyName(rule, value, callback) {
      // console.log(value);
      if (!value) {
        this.submitStatus.companyName = false;
        return callback(new Error(this.$t("consult.error.notNull_company")));
      } else {
        this.submitStatus.companyName = true;
        return callback();
      }
    },

    validatePhoneNumer(rule, value, callback) {
      if (!value) {
        this.submitStatus.phoneNumber = false;
        return callback(new Error(this.$t("consult.error.notNull_number")));
      } else {
        // 如需规则，在此处定义；按需定义，现在直接放行
        this.submitStatus.phoneNumber = true;
        return callback();
      }
    },
    validateEmail(rule, value, callback) {
      if (!value) {
        this.submitStatus.email = false;
        return callback(new Error(this.$t("consult.error.notNull_email")));
      } else {
        const reg =
          /^[-_A-Za-z0-9\.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(value)) {
          this.submitStatus.email = false;
          return callback(new Error(this.$t("consult.error.ture_email")));
        } else {
          this.submitStatus.email = true;
          return callback();
        }
      }
    },
    validateAgreement(rule, value, callback) {
      // console.log(value);
      if (!value) {
        this.submitStatus.agreement = false;
        return callback(new Error(this.$t("consult.seting_g")));
      } else {
        this.submitStatus.agreement = true;
        return callback();
      }
    },
    validateSelect(rule, value, callback) {
      if (!value) {
        this.submitStatus.select = false;
        return callback(new Error(this.$t("consult.error.notNull_content")));
      } else {
        this.submitStatus.select = true;
        return callback();
      }
    },
    validateName(rule, value, callback) {
      if (!value) {
        this.submitStatus.name = false;
        return callback(new Error(this.$t("consult.error.notNull_name")));
      } else {
        this.submitStatus.name = true;
        return callback();
      }
    },
  },
};
</script>
    
<style lang="less" scoped>
.contact-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f5f8;
  position: relative;

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;

    .dialog {
      width: 1100px;
      position: fixed;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      z-index: 2001;
      display: flex;
      flex-direction: column;

      .title {
        height: 110px;
        border-bottom: 1px solid #dddddd;
        margin: 0 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;

        span {
          height: 31px;
          font-size: 24px;
          font-family: 'Meiryooo';
          font-weight: bold;
          line-height: 31px;
          color: #333333;
        }

        .close {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 14px;
          height: 14px;
          color: #000;
          cursor: pointer;
        }
      }

      .dialog-content {
        flex-grow: 1;
        flex-wrap: wrap;

        /deep/.el-radio-group {
          margin: 0 50px;
          margin-top: 50px;
          margin-bottom: 100px;

          .el-radio-button__inner {
            width: 480px;
            height: 66px;
            margin: 10px;
            display: flex;
            align-items: center;
            border: 0;
            background-color: #f2f2f2;
          }

          .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            background-color: #0064e6;
          }

          .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
            box-shadow: none;
          }
        }
      }
    }
  }

  /deep/ .el-form {
    box-sizing: border-box;
    margin: 100px 0 !important;
    width: 1320px !important;
    background-color: #fff;
    padding: 100px 149px 81px 0;

    .el-form-item {
      margin-bottom: 50px;

      // 这里是label
      .agree-checkbox {
        margin-left: 8px;
        color: #0064e6 !important;
        cursor: pointer;
      }

      .el-form-item__label {
        padding: 0 90px 0 0 !important;
      }

      // 这里是按钮样式
      .el-radio-button {
        margin-right: 20px;
      }

      .el-radio-button__inner {
        background: rgb(242, 242, 242);
        color: #6a6a6a;
        border: 0;
        border-radius: 4px;
      }

      .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        background: #0064e6 !important;
        color: #fff !important;
      }

      .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
        box-shadow: none;
      }

      //这里是input样式
      .el-input__inner {
        // background-color: rgba(242, 242, 242, 0.39);
        background: #fff;
      }

      .color {
        background-color: #0064e6;
      }

      .submit {
        width: 220px;
        height: 56px;
        border-radius: 50px;
        color: #fff;
        font-size: 16px !important;
        font-family: 'Meiryooo' !important;
        font-weight: 400 !important;
        line-height: 21px !important;
      }
    }
  }

  .banner {
    position: relative;
    width: 100%;
    height: 600px;
    background: url('../../assets/contact/banner/contact-banner.png') no-repeat center;

    img {
      vertical-align: bottom;
    }

    .banner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 770px;
      height: 120px;
      background: rgba(255, 255, 255, 0.39);
      font-size: 44px;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 59px;
      color: #48464b;
    }
  }

  .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    height: 480px;

    .info {
      flex-grow: 1;
      width: 990px;
      margin: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-size: 30px;
        font-family: 'Meiryooo';
        font-weight: bold;
        line-height: 40px;
        color: #202124;
        text-align: center;
        margin-bottom: 30px;
      }

      li {
        font-size: 16px;
        font-family: 'Meiryooo';
        font-weight: 400;
        line-height: 30px;
        color: #6a6a6a;
        text-align: center;
      }
    }
  }
}
</style>
    